.context-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.context-menu.active {
  display: flex;
  flex-direction: column;
}

.context-menu.active button {
  width: 100%;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding: 5px 8px;
  cursor: pointer;
  color: #111;
}

.context-menu.active button:hover {
  background-color: #159A9C;
  color: #fff;
}

.context-menu hr {
  border: 0;
  border-bottom: 1px solid #aaa;
  background-color: transparent;
  margin: 5px 0;
}

.context-menu .icon {
  opacity: 1;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}