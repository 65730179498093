.book-details-comments {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.book-details-comments .title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
}
.book-details-comments .line {
    height: 1px;
    background-color: #111;
    width: 100%;
    flex: 1 1 0;
}

.commet-reply-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 1rem;
}
.commet-reply-wrapper .actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.commet-reply-wrapper .content {
    width: 100%;
}

.commet-reply-wrapper .content .reply-info {
    background-color: #475569;
    color: #f8fafc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    font-size: 0.8rem;
}
.commet-reply-wrapper .content .reply-text {
    background-color: #f8fafc;
    width: 100%;
    padding: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    font-size: 0.8rem;
    margin-bottom: 1rem;
    border: 1px solid rgba(71, 85, 105, 0.2);
}
