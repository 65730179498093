@font-face {
  font-family: "Tajawal-Black";
  src: url("../fonts/Tajawal-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-Bold";
  src: url("../fonts/Tajawal-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-ExtraBold";
  src: url("../fonts/Tajawal-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-ExtraLight";
  src: url("../fonts/Tajawal-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-Light";
  src: url("../fonts/Tajawal-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-Medium";
  src: url("../fonts/Tajawal-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-Regular";
  src: url("../fonts/Tajawal-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Markazi Text";
  src: url("../fonts/MarkaziText-VariableFont_wght.ttf") format("truetype-variations");
  font-weight: 100 900;
  font-style: normal;
}

/* import Droid Serif font */
@font-face {
  font-family: "Droid Serif";
  src: url("../fonts/DroidSerif.ttf") format("truetype");
  font-weight: 100 900;
  font-style: normal;
}

* {
  box-sizing: border-box;
  /* smooth scroll */
  scroll-behavior: smooth;
  /* smooth font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* smooth image */
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  /* smooth text */
  text-rendering: optimizeLegibility;
  text-rendering: optimizeSpeed;
  text-rendering: geometricPrecision;
}

* html,
body {
  font-family: "Markazi Text", Arial, sans-serif;
}

.book-summary-in-tooltip p {
  font-size: 0.8rem;
}

.book-summary-in-tooltip p:not(:nth-child(2)) {
  margin: 0;
}

/* Player */
.player-wrapper {
  position: relative;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.rating-view-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-i {
  line-height: 1.2rem !important;
  cursor: pointer;
}

.titleWrapBottom-i {
  background-color: rgba(1, 1, 1, 0.8) !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 5px 0 !important;
}

.positionBottom-i {
  background-color: transparent !important;
}

/** End Motion Slider */

.blurred-background-image {
  position: absolute;
  top: 0%;
  left: 0%;
  bottom: 0%;
  right: 0%;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: blur(64px);
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
}

.blurred-background-image img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.change-plan-selected {
  border-color: rgb(59 130 246);
  position: relative;
}

.change-plan-selected:before {
  background: url(https://assets.nflxext.com/en_us/Account/sprite_icons_MyAcct_63x205_v2.png);
  content: "";
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: #077af5;
  position: absolute;
  top: 0%;
  right: 2%;
  transform: translate(30%, -30%);
  background-position: -17px -325px;
}

.audiohat-back-button {
  z-index: 999999;
  position: fixed;
  top: 85px;
  left: 20px;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.cr {
  width: 400px;
  padding: 16px;
  text-align: center;
  color: #f0f0f0;
  z-index: 999999;
  position: fixed;
  top: 55px;
  left: -95px;
  transform: rotate(-45deg);
  background-color: red;
  font-size: 2.5rem;
}

@media screen and (max-width: 820px) {
  .small-screens-text-center {
    text-align: center !important;
  }

  .announcements {
    height: 300px;
  }

  .subscription-benefits li {
    font-size: 0.6rem;
  }
}
