.flat-button {
    display: inline-block;
    font-size: 1rem;
    line-height: 1.1em;
    padding: 12px 1em;
    width: 120px;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    letter-spacing: 0.1px;
    text-align: center;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    position: relative;
    box-sizing: border-box;
}

.flat-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.flat-button:focus {
    outline: none;
}

.flat-button:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

.gray {
    background-color: #e6e6e6;
    background-image: linear-gradient(180deg, #e6e6e6, #ddd);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
    color: #000;
}

.flat-button.gray:hover {
    background: #eaeaea;
}

.blue {
    background-color: #0080ff !important;
    background-image: linear-gradient(180deg, #0080ff, #0272e2);
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.3), 0 1px 0 rgba(0, 0, 0, 0.55);
    color: #fff;
}

.flat-button.blue:hover {
    background-color: #c3c3c3 !important;
    color: #0080ff !important;
}

.red {
    background-color: #ff0000 !important;
    background-image: linear-gradient(180deg, #ff0000, #e20000);
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, 0.3), 0 1px 0 rgba(0, 0, 0, 0.55);
    color: #fff;
}

.flat-button.red:hover {
    background-color: #000 !important;
    color: #ff0000 !important;
}